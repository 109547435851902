@import "../../UI/constants.scss";

.main {
  display: flex;
  position: relative;
  top: 50px;
  width: 50%;
  justify-content: center;
  gap: 20px;
}

.main__dialog {
  flex-grow: 1;
  margin: 0 20px;
}

.search__list {
  padding: 10px;
}

.contacts__list {
  margin: 10px 0;
  overflow: auto;
  height: calc(100vh - 240px);
}

.dialog__user {
  text-align: center;
  margin-bottom: 20px;
  .user__status {
    color: $hintText;
    font-size: 0.8em;
  }
}

.dialog__send-message {
  position: relative;
  margin: 20px auto;
  width: 80%;
  .send-message__value {
    padding: 10px;
    width: 80%;
  }
  .send-message__panel {
    position: absolute;
    top: 50%;
    right: 14%;
    display: flex;
    gap: 15px;
    transform: translate(-14%, -50%);
    .panel__video,
    .panel__record {
      cursor: pointer;
      transition: opacity 0.15s linear;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }
  input {
    display: block;
    margin: 0 auto;
  }
}

.panel__record {
  .active {
    display: none;
  }
}

.main__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
