@import "../../../../UI/constants.scss";

.contact__item {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 5px 10px;
  max-width: 300px;
  &:hover {
    background-color: #fae6b3;
    border-radius: 10px;
  }
}

.contact__avatar {
  border-radius: 50%;
  background-color: gray;
  width: 40px;
  height: 40px;
  position: relative;
  .avatar__first-char {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    color: $mainColor;
  }
  .avatar__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar__online {
    position: absolute;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: rgb(58, 243, 58);
    right: 10%;
    bottom: 15%;
    transform: translate((-10%, -15%));
  }
}

.contact__text {
  column-width: 200px;
  width: 200px;
  text-align: initial;
}
