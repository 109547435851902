@import "../../../UI/constants.scss";

.auth__wrapper {
  max-width: 420px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px 0 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.auth__form_login-button {
  width: 100%;
  margin-bottom: 5px;
}

.hint-text {
  margin-bottom: 20px;
  color: $hintText;
  font-size: 0.8rem;
}

.err-text {
  position: absolute;
  // display: none;
  color: rgb(255, 77, 79);
  top: 62px;
}
