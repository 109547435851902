@import "../UI/constants.scss";

.video__main {
  flex-grow: 1;
}

.video__container {
  .page__title {
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
    margin: 20px auto;
    cursor: pointer;
  }

  .video__list {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .btn {
    border: none;
    background-color: inherit;
    width: 50px;
    margin: 0;
    transition: all 0.3s;
    color: $brightColor;

    &:hover {
      transform: scale(1.1);
    }
  }

  .controls {
    display: flex;
    align-items: self-start;
    justify-content: center;
    gap: 25px;
  }
}

.epty__messages {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
