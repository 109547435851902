.all-users__button {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: #1677ff;
  }
}

.all-users__container {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.all-users__item {
  border-radius: 10px;
  display: flex;
  &:hover {
    background-color: #fae6b3;
  }
}

.contact__choosen {
  background-color: #fae6b3;
}
