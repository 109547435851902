@import "./UI/constants.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: sans-serif;

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffd463;
    border-radius: 0px;
    border: 1px solid black;
  }
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content__container {
  flex-grow: 1;
  padding: 10px 15px;
}

.ant-btn {
  font-family: sans-serif;
}

html[data-theme="dark"] {
  background-color: $mainColorDark;
  color: #fff;
  .header__logo,
  .header__chat {
    color: $mainColor;
  }
  .dialog-message__message {
    background: linear-gradient(
      90deg,
      rgba(255, 212, 99, 0.1) 0%,
      rgba(255, 212, 99, 0) 100%
    );
  }
  .dialog-message__self {
    background: linear-gradient(
      90deg,
      rgba(255, 212, 99, 0) 0%,
      rgba(255, 212, 99, 0.1) 100%
    );
  }

  ::-webkit-scrollbar-track {
    background: #333;
  }
  transition: all 0.5s;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms;
}
